export const images = [
    '/images/hotel/slider/87 FOTO.jpg',
    '/images/hotel/slider/100 FOTO.jpg',
    '/images/hotel/slider/104 FOTO.jpg',
    '/images/hotel/slider/106 FOTO.jpg',
    '/images/hotel/slider/110 FOTO.jpg',
    '/images/hotel/slider/176 FOTO.jpg',
    '/images/hotel/slider/130 FOTO.jpg',
    '/images/hotel/slider/97 FOTO.jpg',
    '/images/hotel/slider/115 FOTO.jpg',
    '/images/hotel/slider/169 FOTO.jpg'
];
