import { Box, Carousel, Heading, Image, Paragraph } from 'grommet';
import { ReactElement } from 'react';

const openVddjPage = (): void => {
    window.open('http://www.valenciadedonjuan.es/');
};

export const Town = (): ReactElement => (
    <Box align="center" pad="large">
        <Heading size="medium" onClick={openVddjPage} style={{ cursor: 'pointer' }}>
            Valencia de Don Juan
        </Heading>
        <Paragraph size="xlarge" textAlign="center">
            Valencia de Don Juan está dotada de una avanzada y cuidada infraestructura que facilita una cómoda y alta calidad de vida a sus habitantes
            y visitantes. Paseando por la ciudad podremos admirar algunos hermosos ejemplos de la arquitectura más tradicional y contemporánea.
        </Paragraph>

        <Box height="large" width="xlarge" overflow="hidden">
            <Carousel fill={true}>
                <Image fit="cover" src="/images/environment/6.jpg" />
                <Image fit="cover" src="/images/environment/7.jpg" />
                <Image fit="cover" src="/images/environment/10.jpg" />
                <Image fit="cover" src="/images/environment/12.jpg" />
                <Image fit="cover" src="/images/environment/13.jpg" />
                <Image fit="cover" src="/images/environment/1.jpg" />
                <Image fit="cover" src="/images/environment/5.jpg" />
                <Image fit="cover" src="/images/environment/8.jpg" />
                <Image fit="cover" src="/images/environment/9.jpg" />
            </Carousel>
        </Box>
    </Box>
);
