import { Box, Button, Text } from 'grommet';
import { ReactElement } from 'react';

export const Booking = (): ReactElement => {
    const openBookingEngine = (): void => {
        window.open('https://www.hotelmanager.es/motor-reserva/api-pms/HotelVillegas/228');
    };

    return (
        <Box direction="row" width="xlarge" justify="center">
            <Button size="large" onClick={openBookingEngine}>
                <Box round="xlarge" background="accent-1" pad={{ vertical: 'medium', horizontal: 'xlarge' }}>
                    <Text size="large" color="brand" weight="bold" textAlign="center">
                        Reservar
                    </Text>
                </Box>
            </Button>
        </Box>
    );
};
